import React, { useMemo } from 'react'
import * as classes from './Finance.module.scss'
import cn from 'classnames'
import Container from '../../ui/Container/Container'
import SectionTitle from '../../ui/SectionTitle/SectionTitle'
import { deleteHtmlTag } from '../../../utils/deleteHtmlTag'
import { useTab } from './useTab'
import Item from './Item'

function Finance({ data, lang }) {
    const sectionTitle = useMemo(() => deleteHtmlTag(data?.sectionTitle?.value, 'p'), [])

    const { activeTab, onChangeActiveTab, tabObj, resultDataTabs, onFormatBytes } = useTab(data)

    if (!data?.tabs?.nodes?.length) {
        return null
    }

    return (
        <section className={classes.Section}>
            <Container>
                {sectionTitle ? <SectionTitle className={classes.SectionTitle} title={sectionTitle} lang={lang} /> : null}

                <div className={classes.Inner}>
                    {resultDataTabs?.length > 0 && (
                        <div className={classes.Tabs}>
                            {resultDataTabs.map((tab) => (
                                <button
                                    className={cn(classes.Tab, { [classes.Active]: tab.name === activeTab.name })}
                                    key={tab.name}
                                    onClick={() => onChangeActiveTab(tab)}
                                >
                                    {tab.name}
                                </button>
                            ))}
                        </div>
                    )}

                    {Object.keys(tabObj)?.length > 0 && (
                        <div className={classes.Content + ' js-finance-content '}>
                            {Object.entries(tabObj)
                                .sort(([a], [b]) => parseFloat(b) - parseFloat(a))
                                .map(([title, value], index) => (
                                    <Item key={index} title={title} value={value} onFormatBytes={onFormatBytes} />
                                ))}
                        </div>
                    )}
                </div>
            </Container>
        </section>
    )
}

export default Finance
