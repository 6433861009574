// extracted by mini-css-extract-plugin
export var Section = "Finance-module--Section--Bdf16";
export var Inner = "Finance-module--Inner--u3VM4";
export var Tabs = "Finance-module--Tabs--SJv+S";
export var Tab = "Finance-module--Tab--KYUm9";
export var Active = "Finance-module--Active--l3APA";
export var Content = "Finance-module--Content--aYe+Z";
export var Item = "Finance-module--Item--vIyOC";
export var ItemTitle = "Finance-module--ItemTitle---BmbW";
export var File = "Finance-module--File--vhf8d";
export var IconPdfWrap = "Finance-module--IconPdfWrap--fU5wo";
export var FileContent = "Finance-module--FileContent--FBqPq";
export var FileText = "Finance-module--FileText--xP+7B";
export var FileSize = "Finance-module--FileSize--eWqhk";
export var FileBtn = "Finance-module--FileBtn--Yc19e";
export var arrowLeft = "Finance-module--arrowLeft--GP+pw";
export var arrowRight = "Finance-module--arrowRight--IO3ZK";