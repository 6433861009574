import React from 'react'

function IconPdf({ className }) {
    return (
        <svg className={className} width={42} height={52} viewBox='0 0 42 52' fill='none' xmlns='http://www.w3.org/2000/svg'>
            <path d='M30.3999 1V11.7H41.0999L30.3999 1Z' fill='#FFC0C8' />
            <path d='M30.3999 1V11.7H41.0999' stroke='#ED1C21' strokeMiterlimit={10} />
            <path d='M41.1 50.9H1V1H30.4L41.1 11.7V50.9Z' stroke='#ED1C21' strokeMiterlimit={10} />
            <path
                d='M8.3999 23.4001H12.2999C14.5999 23.4001 15.8999 24.8001 15.8999 26.8001C15.8999 28.8001 14.5999 30.2001 12.2999 30.2001H10.4999V33.4001H8.3999V23.4001ZM11.8999 28.4001C13.0999 28.4001 13.6999 27.7001 13.6999 26.7001C13.6999 25.7001 13.0999 25.0001 11.8999 25.0001H10.4999V28.4001H11.8999Z'
                fill='#ED1C21'
            />
            <path
                d='M17.1997 33.3V23.3H20.7997C23.7997 23.3 25.5997 25.2 25.5997 28.3C25.5997 31.4 23.7997 33.3 20.6997 33.3H17.1997ZM20.6997 31.5C22.5997 31.5 23.5997 30.5 23.5997 28.3C23.5997 26.1 22.5997 25.1 20.6997 25.1H19.3997V31.5H20.6997Z'
                fill='#ED1C21'
            />
            <path d='M27.1997 23.4001H33.7997V25.1001H29.2997V27.5001H33.2997V29.2001H29.2997V33.3001H27.1997V23.4001Z' fill='#ED1C21' />
        </svg>
    )
}

export default IconPdf
