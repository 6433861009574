import React from 'react'
import * as classes from './Finance.module.scss'
import { Link } from 'gatsby'
import IconPdf from './IconPdf'
import IconRight from '../../icons/IconRight'

function Item({ title, value, onFormatBytes }) {
    return (
        <div className={classes.Item}>
            <span className={classes.ItemTitle}>{title}</span>

            <div className={classes.Files}>
                {value.map((file, fileInex) => (
                    <div className={classes.File} key={fileInex}>
                        <div className={classes.IconPdfWrap}>
                            <IconPdf className={classes.IconPdf} />
                        </div>

                        <div className={classes.FileContent}>
                            {file?.name && <div className={classes.FileText} dangerouslySetInnerHTML={{ __html: file.name }} />}

                            {file?.file?.size && <span className={classes.FileSize}>PDF, {onFormatBytes(file.file.size)} Mb</span>}
                        </div>

                        <a className={classes.FileBtn} href={`${file?.file?.url}`} target='_blank' rel='noopener noreferrer'>
                            <div className={classes.IconRightWrap}>
                                <IconRight />
                            </div>
                        </a>
                    </div>
                ))}
            </div>
        </div>
    )
}

export default Item
