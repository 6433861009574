import React, { useMemo, useState } from 'react'
import anime from 'animejs'

function onFormatBytes(bytes) {
    if (!+bytes) return '0 Bytes'

    const k = 1024

    const i = Math.floor(Math.log(bytes) / Math.log(k))

    return `${parseFloat((bytes / Math.pow(k, i) / 1000).toFixed(2))}`
}

export const useTab = (data) => {
    const defaultDataTabs = data?.tabs?.nodes.map((item) => {
        const arr = data?.files?.nodes?.filter((file) => file.section.name === item.name)

        return { ...item, items: arr }
    })

    const resultDataTabs = defaultDataTabs.filter((item) => item.items.length)

    const [activeTab, setActiveTab] = useState(resultDataTabs?.[0])

    const onChangeActiveTab = (item) => {
        const content = document.querySelector('.js-finance-content')

        anime({
            targets: content,
            duration: 600,
            easing: 'cubicBezier(0.25, 0.1, 0.25, 1)',
            opacity: [1, 0],
            complete: () => {
                setActiveTab(item)

                anime({
                    targets: content,
                    duration: 600,
                    easing: 'cubicBezier(0.25, 0.1, 0.25, 1)',
                    opacity: [0, 1]
                })
            }
        })
    }

    let tabObj = {}

    activeTab?.items?.forEach((item) => {
        const year = new Date(item.date).getFullYear()

        const oldArr = tabObj[year]?.length ? [...tabObj[year]] : []

        if (!tabObj.hasOwnProperty(year)) {
            tabObj[year] = [...oldArr, item]
        }

        tabObj[year] = [...oldArr, item]
    })

    return { activeTab, onChangeActiveTab, tabObj, resultDataTabs, onFormatBytes }
}
