import React, { useContext, useEffect, useRef, useState } from 'react'
import * as classes from './Intro.module.scss'
import cn from 'classnames'
import BreadCrumbs from '../BreadCrumbs/BreadCrumbs'
import Container from '../Container/Container'
import { useWindowSize } from '../../../hooks/useWindowSize'
import { getPhotoSize } from '../../../utils/getPhotoSize'
import { Link } from 'gatsby'
import IconLeft from '../../icons/IconLeft'
import anime from 'animejs'
import { FormContext } from '../../../context'
import { startAnimationTitle } from '../../../utils/animations'
import splitTitleV2 from '../../../utils/splitTitleV2'

function Intro({ crumbs, title, photo, showBackArrow, lang }) {
    const { animationStart } = useContext(FormContext)
    const [animeTitle, setAnimeTitle] = useState(title)
    const windowSize = useWindowSize()
    const imageSize = getPhotoSize(windowSize.width)
    const bg = useRef(null)
    const classAnimationTitle = 'intro-anime-title'

    const goToBackUrl = lang === 'hy' ? '/projects/' : '/en/projects/'

    // Анимация загрузки страницы
    useEffect(() => {
        if (animationStart) {
            if (bg && bg.current) {
                bg.current.style.transform = 'scale(1.3)'

                anime({
                    targets: bg.current,
                    duration: 960,
                    delay: 90,
                    easing: 'cubicBezier(.25, .1, .25, 1)',
                    scale: [1.3, 1]
                })
            }

            splitTitleV2(classAnimationTitle)
        }

        startAnimationTitle(classAnimationTitle, {
            delay: 900
        })
    }, [animationStart, animeTitle])

    return (
        <section className={cn(classes.Intro, { [classes.LangHY]: lang === 'hy' })}>
            {photo && (photo[imageSize] || photo[1920]) ? (
                <div
                    className={classes.Bg}
                    style={{ backgroundImage: `url(${photo[imageSize] ? photo[imageSize] : photo[1920]})` }}
                    ref={bg}
                />
            ) : null}

            <div className={classes.Relative}>
                <Container>
                    <BreadCrumbs list={crumbs} lang={lang} />

                    <div className={classes.TitleWrap}>
                        {showBackArrow && (
                            <div className={classes.LinkWrap}>
                                <Link to={`${goToBackUrl}`}>
                                    <p className={classes.IconLeftWrap}>
                                        <IconLeft className={classes.Arrow} />
                                    </p>
                                </Link>
                            </div>
                        )}

                        {animeTitle ? (
                            <h1 className={`${classes.Title} ${classAnimationTitle}`} dangerouslySetInnerHTML={{ __html: animeTitle }} />
                        ) : null}
                    </div>
                </Container>
            </div>
        </section>
    )
}

export default Intro
